import styles from './styles/QuicklinkBlock.module.scss';
import FontAwesome from "../../../utilities/FontAwesome";
import NavigationLink from "../../../header/navigation/NavigationLink";
import classNames from "classnames";

const LinkItem = (props: {
    title: string,
    link: Navigation,
    icon: string,
    isTerrace?: boolean,
}) => {
    const {title, icon, link, isTerrace} = props;
    const linkItemClassNames= classNames({
        [styles.linkItem]: true,
        [styles.linkItemOnTerrace]: isTerrace,
    })

    return (
        <li className={linkItemClassNames}>
            <FontAwesome fixedWidth name={icon} />
            <span><NavigationLink href={link.url || link.external_url || ''} navigationObj={link}>{title}</NavigationLink></span>
        </li>
    )
};

export default LinkItem;
