import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles/QuicklinkBlock.module.scss';
import FontAwesome from "../../../utilities/FontAwesome";
import {useState} from "react";

const FolderItem = (props) => {
    const [isOpen, setIsOpen] = useState(true);
    const {title, children, icon} = props;

    const listItemClassName = classNames({
        ["sb-block-list-folder"]: true,
        [styles.linkItem]: true,
        [styles.linkItemFolder]: true,
        [styles.linkItemFolderClosed]: !isOpen,
    })

    return (
        <li>
            <span className={listItemClassName} onClick={() => {
                setIsOpen(v => !v)
                if (props.setFileOpenAction) {
                    props.setFileOpenAction(v => !v);
                }
            }}>
                <FontAwesome fixedWidth name={icon} />
                <span className="sb-organization-color-block-font">{title}</span>
            </span>

            {isOpen && <ul>
                {children}
            </ul>}
        </li>
    )
};
FolderItem.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.object.isRequired,
    icon: PropTypes.string.isRequired,
    setFileOpenAction: PropTypes.func,
};

export default FolderItem;
