import FontAwesome from "../../../utilities/FontAwesome";
import {isFolder, isLink} from "../../../modals/AdminModal/utilities";
import styles from "./styles/QuicklinkBlock.module.scss";
import {conditionType, currentViewType} from "../../../modals/AdminModal/AdminModalTypes";
import FlatIcon from "../../../utilities/FlatIcon";
import * as React from "react";
import CleverSiteTreeTheme from "../../../admin/tree/CleverSiteTreeTheme";
import {useContext, useMemo} from "react";
import {StoreContext} from "../../../../stores/StoreLoader";
import {numExpandedNodes} from "../../../admin/tree/Tree";
import {convertFlatToTree, convertTreeToFlat} from "../../../../utils/DataUtilities";
import { SortableTreeWithoutDndContext } from "react-sortable-tree";
import NavigationModel from "../../../../utils/NavigationModel";

export function QuicklinkEditTree(props: {
    rowHeight?: number,
    links: Array<EditorQuickLink | Navigation>,
    updateNavigations: (navigations: Array<EditorQuickLink | Navigation>, uiOnly: boolean) => void,
    updateNavigation: (navigation: EditorQuickLink | Navigation) => void,
    createNavigation: (navigation: EditorQuickLink) => void,
    deleteNavigations: (navigations: Navigation[]) => void,
    allowSubFolders: boolean,
    parent_id?: string,
    terrace_id?: number,
}) {
    const {modalStore} = useContext(StoreContext);

    const {rowHeight = 60} = props;
    const linksAsTree = props.links ? convertFlatToTree(props.links) : [];

    const height = useMemo(() => {
        return numExpandedNodes(linksAsTree) * rowHeight + 20;
    }, [numExpandedNodes(linksAsTree)])

    function createNavigation(navigation: EditorQuickLink) {
        props.createNavigation(navigation);
    }

    async function handleNavigationsMove(obj) {
        const basePath = props.parent_id ? props.parent_id.replaceAll("-", "") : props.terrace_id;
        props.updateNavigations(convertTreeToFlat(obj.treeData, basePath), false);
    }

    function handleAddNew(folder: boolean, node: Navigation = new NavigationModel()) {
        node.icon = folder ? "" : "far fa-circle";

        modalStore.addModal({
            type: "admin",
            condition: node.path ? conditionType.ADD_SUBLINK : conditionType.ADD_TOPLINK,
            nodeInfo: node,
            navigation: props.links,
            parent_id: props.parent_id,
            terrace_id: props.terrace_id,
            initialView: folder ? currentViewType.folder : currentViewType.quicklink,
            navigationUpdater: navigations => props.updateNavigations(navigations, false),
            navigationCreator: createNavigation,
        })
    }

    function canDrop(node) {
        if (props.allowSubFolders) {
            // can drop if at top level OR next parent is a folder
            return !node.nextParent || (node.nextParent && !isLink(node.nextParent));
        } else {
            // can drop if at top level OR next parent is a folder and current node is a link
            return !node.nextParent || (node.nextParent && !isLink(node.nextParent) && isLink(node.node));
        }
    }

    return <div>
        <div>
            <button
                key="addLink"
                onClick={() => handleAddNew(false)}
                className="btn btn-default btn-sm"
                type="button"
            >
                <FontAwesome name={"fa-link"} prefix={"fas"} fixedWidth/> Add New Link
            </button>
            <button
                key="addFolder"
                onClick={() => handleAddNew(true)}
                className="btn btn-default btn-sm"
                type="button"
            >
                <FontAwesome name={"fa-folder-open"} prefix={"fas"} fixedWidth/> Add New folder
            </button>
        </div>
        <div id="quick-link-form">
            <SortableTreeWithoutDndContext
                style={{height: height}}
                treeData={linksAsTree}
                onChange={() => {
                }}
                canDrop={canDrop}
                onMoveNode={handleNavigationsMove}
                onVisibilityToggle={({node, expanded}) => {
                    props.updateNavigations((props.links as NavigationsLinksType).map(n => {
                        if (n.path === node.path) {
                            n.expanded = expanded;
                        }
                        return n;
                    }), true)
                }}
                theme={CleverSiteTreeTheme}
                rowHeight={rowHeight}
                scaffoldBlockPxWidth={30}
                generateNodeProps={rowInfo => {
                    const buttons = [
                        <button key="editLink" className={styles.editNodeButton} onClick={() => {
                            modalStore.addModal({
                                type: "admin",
                                condition: conditionType.EDITING,
                                nodeInfo: rowInfo.node,
                                navigation: props.links,
                                initialView: rowInfo.node.type === 1 ? currentViewType.folder : undefined,
                                navigationUpdater: props.updateNavigation,
                                navigationCreator: createNavigation,
                            })
                        }}>
                            <FlatIcon ariaHidden={false} name={"flaticon-draw"}/>
                        </button>,
                        <button
                            key="delete"
                            className={styles.editNodeButton}
                            onClick={async () => {
                                if (confirm("Are you sure you want to do that?")) {
                                    const includeAllChildren = (props.links as NavigationsLinksType).filter(item => {
                                        return item.path.slice(0, rowInfo.node.path.length) === rowInfo.node.path;
                                    });
                                    props.deleteNavigations(includeAllChildren);
                                }
                            }}
                            aria-label={"Delete"}
                        >
                            <FlatIcon ariaHidden={false} name={"flaticon-remove"}/>
                        </button>,
                    ];

                    const nodeIsFolder = isFolder(rowInfo.node);
                    if (nodeIsFolder) {
                        buttons.unshift(<button
                            key="editNode"
                            className={styles.editNodeButton}
                            onClick={() => handleAddNew(false, rowInfo.node)}>
                            <FontAwesome ariaHidden={false} prefix={'fas'} name={"fa-plus"}/>
                        </button>)
                    }

                    return {
                        title: <div className={styles.editNodeTitle}>
                            <FontAwesome name={nodeIsFolder ? "far fa-folder-open" : rowInfo.node.icon}/>
                            <span>{rowInfo.node.title}</span>
                        </div>,
                        className: styles.treeNode,
                        buttons,
                    }
                }}
                canNodeHaveChildren={node => Array.isArray(node.children)}
            />
        </div>
    </div>
}