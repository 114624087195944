import React, {useContext, useEffect, useState} from "react";
import ListOfItems from "../../SchoolBlocks/QuickLinksBlock/ListOfItems";
import {convertFlatToTree} from "../../../../utils/DataUtilities";
import BlockTitle from "../_BlockTitle";
import quickLinkStyles from "../../SchoolBlocks/QuickLinksBlock/styles/QuicklinkBlock.module.scss";
import styles from "./styles/LinksTerrace.module.scss";
import classNames from 'classnames';
import {QuicklinkEditTree} from "../../SchoolBlocks/QuickLinksBlock/QuicklinkEditTree";
import {observer} from "mobx-react";
import WatsonApi from "../../../../backends/WatsonApi";
import NotificationManager from "../../../notifications/NotificationManager";
import CleverSlider from "../../../utilities/CleverSlider/CleverSlider";
import {StoreContext} from "../../../../stores/StoreLoader";
import {EditDropDownItem} from "../EditDropDown";
import FontAwesome from "../../../utilities/FontAwesome";

const LinksTerrace = observer((props: {
    blockObj: ILinksTerrace,
    aboveTheFold: boolean,
}) => {
    const {interfaceStore, organizationStore} = useContext(StoreContext);
    const [editing, setEditing] = useState(false);
    const [height, setHeight] = useState(325);
    const [fileOpenAction, setFileOpenAction] = useState<boolean | undefined>(false);

    const wrapperClassName = classNames({
        [quickLinkStyles.blockList]: true,
        [styles.container]: true,
    })

    async function handleCreateNavigation(navigation) {
        try {
            const client = await WatsonApi();
            const response = await client.apis.organizations.organizations_terraces_navigation_create({
                organization_pk: props.blockObj.organization_id,
                terrace_pk: props.blockObj.id,
                data: {
                    title: navigation.title,
                    type: navigation.type,
                    external_url: navigation.external_url,
                    icon: navigation.icon,
                    class_field: navigation.class_field,
                    description: navigation.description,
                    path: navigation.path,
                    enabled: navigation.enabled,
                    terrace: navigation.terrace,
                    organization_id: navigation.organization_id,
                },
            });
            const newNav = JSON.parse(response.data);
            props.blockObj.links = [...props.blockObj.links, newNav];
            return newNav;
        } catch (e) {
            NotificationManager.error(e.message);
            return null;
        }
    }

    async function handleUpdateNavigations(navigations, uiOnly) {
        try {
            if (uiOnly) {
                // this is used when expanding a navigation when we don't actually need to update anything
                props.blockObj.links = navigations;
            } else {
                const client = await WatsonApi();
                const response = await client.apis.organizations.organizations_terraces_navigation_bulk_update({
                    organization_pk: props.blockObj.organization_id,
                    terrace_pk: props.blockObj.id,
                    data: navigations,
                });
                const newNavigations = JSON.parse(response.data);
                props.blockObj.links = newNavigations.map(newNav => {
                    const currentNav = props.blockObj.links.find(nav => nav.id === newNav.id);
                    if (currentNav) {
                        newNav.expanded = currentNav.expanded;
                    }
                    return newNav;
                });
                NotificationManager.success("Links successfully updated!");
            }
        } catch (e) {
            NotificationManager.error(e.message);
        }
    }

    async function handleDeleteNavigations(navigations) {
        try {
            const navigationIds = navigations.map(n => n.id);
            const client = await WatsonApi();
            await client.apis.organizations.organizations_terraces_navigation_bulk_destroy({
                organization_pk: props.blockObj.organization_id,
                terrace_pk: props.blockObj.id,
                data: navigationIds,
            });
            props.blockObj.links = props.blockObj.links.filter(n => !navigationIds.includes(n.id));
        } catch (e) {
            NotificationManager.error(e.message);
        }
    }

    async function updateNavigation(navigation) {
        const client = await WatsonApi();
        const response = await client.apis.organizations.organizations_terraces_navigation_partial_update({
            organization_pk: props.blockObj.organization_id,
            terrace_pk: props.blockObj.id,
            id: navigation.id,
            data: navigation,
        })
        const newNav = JSON.parse(response.data);
        props.blockObj.links = props.blockObj.links.map(nav => {
            if (nav.id === newNav.id) {
                return newNav;
            }
            return nav;
        })
    }

    const treeData = convertFlatToTree(props.blockObj.links);

    useEffect(() => {
        const array = Array.from(document.getElementsByClassName("slideItemlinks"));
        const heights:number[] = [];
        array.forEach(item => {
            heights.push(item.clientHeight);
        })
        setHeight(Math.max(...heights));
    },[fileOpenAction])

    const slideItemClassName = classNames({
        [styles.slideItem]: true,
    })

    function _setEditing(value, cancel) {
        setEditing(value);
    }

    const linksTerraceDropDownItems: EditDropDownItem[] = [
        {
            title: editing ? 'Close Editor' : 'Add / Edit Links',
            icon: <FontAwesome prefix={'fas'} fixedWidth className="fa-pencil-alt"/>,
            onClick: () => _setEditing(!editing, true),
        },
    ]

    const content = interfaceStore.breakpoint !== 'break-point-xs' ? <CleverSlider
            totalSlides={treeData.length}
            height={height}
            padding={40}
            containerClassName={styles.cleverSliderContainer}
            blockObj={props.blockObj}
            scrollArrowClassName={styles.scrollArrow}
        >
        {treeData.map(link => {
            return <div className={slideItemClassName} style={{maxWidth: treeData.length > 1 ? "60vw" : "80vw"}}>
                <ul>
                    <ListOfItems items={[link]} setFileOpenAction={setFileOpenAction} isTerrace={true}/>
                </ul>
            </div>
        })}
    </CleverSlider> : <div className={styles.childSlideContainerMobile}>
        {treeData.map(link => {
            return <div className={slideItemClassName} style={{width: "80vw"}}>
                <ul>
                    <ListOfItems items={[link]} setFileOpenAction={setFileOpenAction} isTerrace={true}/>
                </ul>
            </div>
        })}
    </div>

    return <article>
        <BlockTitle
            blockObj={props.blockObj}
            editing={editing}
            setEditing={_setEditing}
            aboveTheFold={props.aboveTheFold}
            dropDownItems={linksTerraceDropDownItems}
            allowSave={true}
            showViewMore={false}
        />
        <div className={wrapperClassName}>
            {editing ? <QuicklinkEditTree
                links={props.blockObj.links}
                createNavigation={handleCreateNavigation}
                updateNavigations={handleUpdateNavigations}
                deleteNavigations={handleDeleteNavigations}
                updateNavigation={updateNavigation}
                allowSubFolders={false}
                terrace_id={props.blockObj.id}
            /> : <div>
                {content}
            </div>
            }
        </div>

    </article>
})

export default LinksTerrace;
