import FolderItem from './FolderItem';
import LinkItem from './LinkItem';
import {unescapeHtml} from "../../../../utils/StringUtilities";
import {isFolder} from "../../../modals/AdminModal/utilities";
import React, {SetStateAction} from "react";

interface ListOfItemsProps {
    items: Array<any> | Object,
    setFileOpenAction?: React.Dispatch<SetStateAction<boolean | undefined>>,
    isTerrace: boolean,
}

const ListOfItems = (props: ListOfItemsProps) => {
    const {items = []} = props;
    let title;

    return <>{(items as Array<any>).map((link, i) => {

        title = unescapeHtml(link.title);

        if (isFolder(link)) {
            return (
                <FolderItem key={link.id || i} title={title} icon={"fas fa-chevron-down"} setFileOpenAction={props.setFileOpenAction && props.setFileOpenAction}>
                    <ListOfItems key={link.id | i} items={link.children} isTerrace={props.isTerrace}/>
                </FolderItem>
            );
        } else {
            return (
                <LinkItem key={link.id || i} link={link}
                          icon={link.icon || "far fa-circle"} title={title} isTerrace={props.isTerrace}/>
            );
        }
    })}</>;
};

export default ListOfItems;
